















































































































































































































































import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({
  components: {
    CharacterRow: () => import("./character-row.vue"),
    APRDetailTable: () => import("./APRDetailTable.vue"),
    APRDetail: () => import("./APRDetail.vue"),
  },
})
export default class StakedCard extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;

  walletStore = walletStore;
}
